/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://2vegdfhqird5re3j2fsjs67k5y.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-2:826462c1-f150-46d8-a74a-68352505f0a2",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_Ez4o8ovlm",
    "aws_user_pools_web_client_id": "34gpjt2o4scbqql50c8k65aegh",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "mcchubdashboard-hostingbucket-stagehub",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://dhikxors3qhz6.cloudfront.net"
};


export default awsmobile;
